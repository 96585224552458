import axios from 'axios';
import { getBaseUrl } from '../config/retailerConfig';
import accountActions from '../redux/actions/accountActions';

export const signup = 
 (
  username, 
  password,
  phone,
  first_name,
  last_name,
  month,
  year,
  address1,
  address2,
  city,
  state,
  zip,
  country,
  retailer
  ) =>
{
  let payloadData = 
  {
    "firstName": first_name,
    "lastName": last_name,
    "email": username,
    "phone":phone,
    "password": password,
    "birthMonth": month,
    "birthYear": year,
    "address1": address1,
    "address2": address2,
    "city": city,
    "state": state,
    "zip": zip,  
}
  if(country){
    // adding to avoid blank country field - 400 bad request
    payloadData.country=country
  }
  return axios.post(
    `${getBaseUrl(retailer)}/user/register`,
    payloadData
      ,
    {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': retailer,
      },
    }
  );
}

export const login = (username, password, retailer) =>
  axios.post(
    `${getBaseUrl(retailer, true)}/user/login`,
    {
      username,
      password,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': retailer,
      },
    }
);

export const forgotPassword = (email, retailer) =>
  axios.post(
    `${getBaseUrl(retailer, true)}/user/password/forgot`,
    { email },
    {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': retailer,
      },
    }
);

export const getUserInfo = async (token, retailer) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-retailer': retailer,
    'x-auth-token': token,
  };
  try {
    const response = await axios.get(`${getBaseUrl(retailer)}/user`, { headers });
    response.data.token = token;
    return response.data;
  } catch (error) {
    return { error };
  }
};

export const getUserPoints = async (retailer) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-retailer': retailer?.dpn_instance,
    'x-auth-token': localStorage.getItem('token'),
  };

  try {
    const response = await axios.get(`${getBaseUrl(retailer.dpn_instance)}/loyalty`, { headers });
    return response.data;
  } catch (error) {
    return { error };
  }
};
export const changePassword = async (passwordData, retailer) => {
  const headers = {
    "Content-Type": "application/json",
    "x-retailer": retailer,
    "x-auth-token": localStorage.getItem("token"),
  };
  return axios
    .post(
      `${getBaseUrl(retailer, true)}/user/password`,
      passwordData,
      { headers }
    )
    .then((response) => response.data)
    .catch((error) => ({
      error: "The current password you entered is incorrect. Please try again",
      fields: error.response?.data?.fields,
      message: error.response?.data.message,
    }));
};

export const updateUserCall = async (userData, retailer) => {
  const headers = {
    "Content-Type": "application/json",
    "x-retailer": retailer,
    "x-auth-token": localStorage.getItem("token"),
  };

  return axios
    .post(`${getBaseUrl(retailer)}/user`, userData, { headers })
    .then((response) => {
      if (response.data.status == 404) {
        return {
          error: "Error.userProfileError",
          fields: response.data?.fields,
        };
      }
      if (response.data.status == 401) {
        return {
          error: {
            code: 401,
            status: "Token Expired",
          },
        };
      }
      return response.data;
    })
    .catch((error) => ({
      error: "Error.userProfileError",
      fields: error.response?.data?.fields,
      message: error.response?.data.message,
    }));
};

export const updateUserSuccess = (retailer) => async (dispatch) => {
  const userToken = localStorage.getItem("token");
  const userData = await getUserInfo(localStorage.getItem("token"), retailer);
  if (!userData.error) {
    userData.token = userToken;
    userData.totalsLastUpdate = new Date().toString();
    dispatch(accountActions.setUserDetails(userData))
    localStorage.setItem("userData", JSON.stringify(userData));
  }
};

export const createUserCall = async (userData, retailer) => {
  const headers = {
    "Content-Type": "application/json",
    "x-retailer": retailer,
  };

  return axios
    .post(`${getBaseUrl(retailer)}/user/register`, userData, {
      headers,
    })
    .then((response) => {
      if (response.data.status == 404) {
        return {
          error:
            "Your request cannot be completed. Please try again. If it continues to happen, please contact the support team.",
          fields: response.data.fields,
        };
      }
      if (response.data.status == 409) {
        return {
          error: "Your registration could not be processed, there is a conflict in the data.",
          fields: response.data.fields,
        };
      }

      if (response.data.status == 400) {
        return {
          error:
            "Your request cannot be completed. Please try again. If it continues to happen, please contact the support team.",
          fields: [],
        };
      }
      localStorage.setItem("token", response.data.token);
      return response.data.token;
    })
    .catch((error) => {
      if (error.response.data.status == 409) {
        return {
          error: "Your registration could not be processed, there is a conflict in the data.",
          fields: error.response.data.fields,
        };
      } else {
        return {
          error:
            "Your request cannot be completed. Please try again. If it continues to happen, please contact the support team.",
          fields: [],
        };
      }
    });
};

export const createUser = (data, retailer) => async () => {
  const userToken = await createUserCall(data, retailer);
};