import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function CPGPage({ page }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (page) {
      navigate(`/${page[0].brandsite_name}/home`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      Loading...
    </>
  );
}

export default CPGPage;
