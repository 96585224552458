import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { formStyles } from "../styles/cpg/form_styles";
import { useNavigate } from "react-router-dom";
import LoyaltyProgress from "./LoyaltyProgress";

function ProgramPod(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& :hover": {
        backgroundColor: "transparent",
      },
      width: "384px",
      padding: "0px",
      maxWidth: "384px!important",
      background: "#55555",
      borderRadius: "var(--radius--xl, 24px)",
      background: "var(--Background-backgroundPrimary, #FFF)",
      boxShadow:
        "0px 170px 48px 0px rgba(29, 60, 109, 0.00), 0px 109px 44px 0px rgba(29, 60, 109, 0.01), 0px 61px 37px 0px rgba(29, 60, 109, 0.05), 0px 27px 27px 0px rgba(29, 60, 109, 0.09), 0px 7px 15px 0px rgba(29, 60, 109, 0.10), 0px 0px 0px 0px rgba(29, 60, 109, 0.10)",
      [theme.breakpoints.between("xs", "sm")]: {
        display: "flex",
        width: "171px",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "var(--radius--md, 8px)",
        boxShadow:
          "0px 38px 11px 0px rgba(29, 60, 109, 0.00), 0px 24px 10px 0px rgba(29, 60, 109, 0.01), 0px 14px 8px 0px rgba(29, 60, 109, 0.05), 0px 6px 6px 0px rgba(29, 60, 109, 0.09), 0px 2px 3px 0px rgba(29, 60, 109, 0.10), 0px 0px 0px 0px rgba(29, 60, 109, 0.10)",
      },
    },
    media: {
      height: "200px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      alignSelf: "stretch",
      [theme.breakpoints.between("xs", "sm")]: {
        height: "96px",
        width: "171px",
      },
    },
    contentContainer: {
      padding: "24px",
      [theme.breakpoints.between("xs", "sm")]: {
        padding: "8px",
      },
    },
    points: {
      color: "#0073C5",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "150%",
      paddingBottom: "4px",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "12px",
      },
    },
    title: {
      fontSize: "31px",
      fontWeight: "700",
      lineHeight: "34.1px",
      textAlign: "left",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      alignSelf: "stretch",
      padding: "4px 0px",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "14px",
        lineHeight: "150%",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
      },
    },
    content: {
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "140%",
      textAlign: "left",
      color: "#25272C",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      padding: "4px 0px",
      height: "58px",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "12px",
        lineHeight: "150%",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "44px",
      },
    },
    date: {
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "25.2px",
      textAlign: "left",
      color: "#6B7280",
      lineHeight: "140%",
      padding: "4px 0px",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "12px",
        lineHeight: "120%",
      },
    },
    buttonContainer: {
      [theme.breakpoints.between("xs", "sm")]: {
        padding: "10px 8px",
      },
    },
  }));
  const classes = useStyles();
  const globalClasses = formStyles(props);
  const programs = props.programs;
  const navigate = useNavigate();
  const loggedIn = !!localStorage.getItem("token");
  const [displayValue, setDisplayValue] = useState("");

  //   calculating dates starts //
  const MonthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function DaylightSavingTimeAdjustment(localeDate) {
    const date = new Date(localeDate);
    if (date.getHours() == 0) {
      date.setHours(date.getHours() - 1);
    }
    return date;
  }

  const expirationOfferDate = DaylightSavingTimeAdjustment(
    new Date(programs.expirationDate).toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    })
  );

  const expirationMonth = MonthNames[new Date(expirationOfferDate).getMonth()];
  const expirationDate = `${new Date(
    expirationOfferDate
  ).getDate()}, ${new Date(expirationOfferDate).getFullYear()}`;

  //   calculating dates end //

  function enroll(id) {
    if (!loggedIn) {
      navigate(`/${props.brandsite_name}/login`);
    } else {
      props.enrollCurrentProgram(id);
    }
  }

  function setValue(val) {
    const displayUnit = programs.pointsDisplayUnit;
    if (displayUnit.includes("spend")) {
      setDisplayValue(`$ ${val}`);
    }
    if (displayUnit.includes("item")) {
      setDisplayValue(`${val} items`);
    }
    if (displayUnit.includes("point")) {
      setDisplayValue(`${val} points`);
    }
    if (val === undefined) {
      setDisplayValue(`Completed`);
    }
  }
  return (
    <Card className={classes.root}>
      {/* showing card for loaded data */}
      {
        <div>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={programs.imageUrl}
              title=""
            />

            <CardContent
              style={{ padding: "24px;" }}
              className={classes.contentContainer}
            >
              <Typography className={classes.points}>{displayValue}</Typography>
              <Typography className={classes.title}>
                {programs.title}
              </Typography>
              <Typography className={classes.content}>
                {programs.terms}
              </Typography>
              <Typography className={classes.date}>
                Expires: {expirationMonth}
                {expirationDate}
              </Typography>
            </CardContent>
          </CardActionArea>
          {programs.status !== "available" && (
            <div>
              {programs.status !== "available" && (
                <LoyaltyProgress
                  setValue={setValue}
                  offerInfo={programs}
                  color={props.color_background}
                ></LoyaltyProgress>
              )}
            </div>
          )}

          {programs.status === "available" && (
            <div className={classes.buttonContainer}>
              {
                <Button
                  className={globalClasses.EnrollBtn}
                  onClick={() => enroll(programs.id)}
                >
                  {loggedIn ? "Enroll" : "Login To Enroll"}
                </Button>
              }
            </div>
          )}
        </div>
      }
    </Card>
  );
}

export default React.memo(ProgramPod);
