import React from 'react'
import { SwipeableDrawer, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { default as frame1 } from "../assets/images/svg/frame1.svg";
import { default as frame2 } from "../assets/images/svg/frame2.svg";
import { default as frame3 } from "../assets/images/svg/frame3.svg";
import { default as closeMobileModalIcon } from "../assets/images/svg/closeMobileModalIcon.svg";

function MobileSwipeableDrawer({ toggleDrawer, open }) {
    const useStyles = makeStyles((theme) => ({
        drawerContainer: {
            '& .MuiDrawer-paperAnchorBottom': {
                borderRadius: '12px 12px 0px 0px',
                boxShadow: '0px -12px 8.5px 0px rgba(29, 60, 109, 0.25)',
            }
        },
        title: {
            color: "#0073C5",
            fontSize: '24px',
            fontWeight: 800,
            lineHeight: '107%',
            letterSpacing: '-.61px',
            width: '282px'
        },
        heading: {
            color: "#25272C",
            fontSize: '20px',
            fontWeight: 800,
            lineHeight: '90%',
            letterSpacing: '-.27px'
        },
        list: {
            color: "#6B7280",
            paddingLeft: '20px',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '150%',
        }
    }));
    const classes = useStyles();
    const list = (
        <Grid item xs={12} style={{ padding: '33px 22px 22px 22px', maxHeight: '695px' }}>
            <Grid item xs={12} style={{ borderRadius: '12px' }}>
                <Grid container>
                    <Grid item xs={10}>
                        <h2 className={classes.title}>
                            How to Upload Your Receipt
                        </h2>
                    </Grid>
                    <Grid item xs={2}>
                        <Button className={classes.closeButton} onClick={toggleDrawer(false)}>
                            <img src={closeMobileModalIcon}></img>
                        </Button>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '4%' }}>
                        {/* step 0 */}
                        <div style={{ display: 'flex' }}>
                            <div style={{ position: 'relative' }}>
                                <img src={frame1}></img>
                            </div>
                            <div style={{ paddingLeft: '24px' }}>
                                <h4 className={classes.heading}>
                                    Select Offers and Shop
                                </h4>
                                <ul className={classes.list}>
                                    <li>Enroll in the offer(s) you're interested in and purchase the required item(s) in-store or online.</li>
                                    <li>Remember to save your receipts!</li>
                                </ul>
                            </div>
                        </div>

                        {/* step 1 */}
                        <div style={{ display: 'flex' }}>
                            <div style={{ position: 'relative' }}>
                                <img src={frame1}></img>
                            </div>
                            <div style={{ paddingLeft: '24px' }}>
                                <h4 className={classes.heading}>
                                    Prepare Your Receipt
                                </h4>
                                <ul className={classes.list}>
                                    <li>Ensure it includes the required items and the purchase date.</li>
                                    <li>Make sure all details are legible.</li>
                                    <li>For long receipts, take multiple photos.</li>
                                </ul>
                            </div>
                        </div>
                        {/* step 2 */}
                        <div style={{ display: 'flex' }}>
                            <div style={{ position: 'relative' }}>
                                <img src={frame2}></img>
                            </div>
                            <div style={{ paddingLeft: '24px' }}>
                                <h4 className={classes.heading}>
                                    Take a Photo
                                </h4>
                                <ul className={classes.list}>
                                    <li>Position you receipt in front of your webcam and ensure it fits within the frame.</li>
                                    <li>Click the “Capture” button to take the photo. Review the photo for clarity and retake if necessary.</li>
                                </ul>
                            </div>

                        </div>
                        {/* step 3 */}
                        <div style={{ display: 'flex' }}>
                            <div style={{ position: 'relative' }}>
                                <img src={frame3}></img>
                            </div>
                            <div style={{ paddingLeft: '24px' }}>
                                <h4 className={classes.heading}>
                                    Submit Your Photo
                                </h4>
                                <ul className={classes.list}>
                                    <li>Click “Submit” to upload your receipt photo. Points may take up to two days to appear in your account.</li>
                                </ul>
                            </div>
                        </div>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )


    return (
        <div>
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                className={classes.drawerContainer}
            >
                {list}
            </SwipeableDrawer>
        </div>
    )
}

export default MobileSwipeableDrawer