import { SET_USER_LOGIN_STATUS, SET_LOYALITY_POINTS, USER_DETAILS } from "../reducers/user";
import { SET_RETAILER_CONFIG } from '../reducers/config';
import { getUserPoints }  from "../../service/account_service";

const accountActions = {
  updateUserLytPoints(retailer) {
    return async (dispatch) => {
      const history = await getUserPoints(retailer);
      const data = {
        points:
          history.items && history.items.length > 0
            ? history.items[0].targetBalance
            : 0,
      };
      dispatch(SET_LOYALITY_POINTS(data));
    };
  },

  setUserLoginStatus() {
    return async (dispatch) => {
      dispatch(SET_USER_LOGIN_STATUS());
    };
  },

  setUserDetails(data) {
    return async (dispatch) => {
      dispatch(USER_DETAILS(data));
    };
  },

  serRetailerConfig(config) {
    return async (dispatch) => {
      dispatch(SET_RETAILER_CONFIG(config));
    };
  }
};

export default accountActions;
