import React from 'react';
import { shouldRenderComponent } from '../shared/util/ComponentAuth';
import './contact.scss'

function ContactBlock(props) {
  // Find the FooterBlock in the components array
  const block = props.page.components.find(component => component.block_type === 'ContactBlock');

  if (!shouldRenderComponent(block.auth_visibility)) {
    return null;
  }

  // Extract data from the FooterBlock
  const {
    title,
    phone,
    email,
    text_color,
    icon_color,
    background_color,
  } = block;

  // Apply the extracted color values to styles
  const contactStyle = {
    backgroundColor: background_color,
    color: text_color,
  };

  return (
    <div className="contact-content" style={contactStyle}>
      <h1>{title}</h1>
      <h3><svg width="24" height="18" viewBox="0 0 24 18" fill={icon_color} xmlns="http://www.w3.org/2000/svg">
<path d="M0 3C0 1.35938 1.3125 0 3 0H21C22.6406 0 24 1.35938 24 3V15C24 16.6875 22.6406 18 21 18H3C1.3125 18 0 16.6875 0 15V3ZM2.25 3V4.07812L10.3125 10.6875C11.2969 11.4844 12.6562 11.4844 13.6406 10.6875L21.75 4.07812V3C21.75 2.625 21.375 2.25 21 2.25H3C2.57812 2.25 2.25 2.625 2.25 3ZM2.25 6.98438V15C2.25 15.4219 2.57812 15.75 3 15.75H21C21.375 15.75 21.75 15.4219 21.75 15V6.98438L15.0938 12.4219C13.2656 13.9219 10.6875 13.9219 8.85938 12.4219L2.25 6.98438Z" fill={icon_color}></path>
</svg> {email}</h3>
      <h4>{phone}</h4>
    </div>
  );
}

export default ContactBlock;
