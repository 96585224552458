import React from "react";
import { Box, makeStyles, Typography, Paper } from "@material-ui/core";
import FormWrapper from "./FormWrapper";
import HeaderNavigation from "../headerNavigation";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";

export function Profile(props) {
  const { retailer, isMobile, onClose } = props;
  const signupConfig = props.retailer.components.find(
    (component) => component.block_type === "SignupBlock"
  );
  const header = props.retailer.components.find(
    (component) => component.block_type === "HeaderNavigationBlock"
  );
  const dpnInstance = props?.retailer?.dpn_instance?.dpn_instance;
  const formFields = signupConfig?.retailerFields?.formFields;

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      overflow: "hidden",
      [theme.breakpoints.down("xs")]: {
        "& .tabPanelSection": {
          display: "none",
        },

        "& .MuiTypography-h2": {
          fontSize: "3.0rem",
        },
      },
    },
    content: {
      backgroundColor: theme.palette.background.paper,
      margin: "0 10px",
      outline: "none",
      textAlign: "center",
    },
    header: {
      display: "flex",
      "& .pageTitle": {
        marginLeft: "20px",
        fontFamily: "InterMedium",
      },
    },
    mobileHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& .backImgBtn": {
        marginTop: "10px",
      },

      "& .pageTitle": {
        marginTop: "10px",
        fontSize: "26px",
      },
    },
    profileBody: {
      margin: "20px 0",
      backgroundColor: "#EEF0F2",
      borderRadius: "10px",

      "& .MuiTypography-h4": {
        padding: "20px 0",
      },

      "& .MuiTypography-h5": {
        fontWeight: 300,
        padding: "20px 0",
      },

      "& .MuiButton-root": {
        fontSize: "14px !important",
        color: "#fff !important",
      },
    },
    profileBodyMob: {
      height: "82vh",
      overflowY: "scroll",
      overflowX: "hidden",
      paddingBottom: "2rem",
    },
    paper: {
      maxWidth: 630,
      boxShadow: "none",
    },
    paperMobile: {
      maxWidth: 560,
      margin: `${theme.spacing(1)}px auto`,
      padding: "28px 2px 10px 2px",
      boxShadow: "none",
    },
    btn: {
      width: "40%",
      backgroundColor: `${retailer.mainColor}`,
      textTransform: "capitalize",

      "&:hover": {
        boxShadow: "none",
        backgroundColor: retailer.mainColor,
      },
      "&:focus": {
        boxShadow: "none",
        backgroundColor: retailer.mainColor,
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
  }));
  const classes = useStyles();

  return (
    <Paper className={isMobile.isMobile ? classes.paperMobile : classes.paper}>
      <Box
        style={{
          width: "100%",
          height: isMobile.isMobile ? "100vh" : "100%",
          background: "#fff",
        }}
      >
        {isMobile && header && <HeaderNavigation page={props.retailer} />}
        <div className={classes.content}>
          <div className={isMobile ? classes.mobileHeader : classes.header}>
            <div className="backImgBtn">
              {isMobile && (
                <button
                  style={{ background: "none", border: "none" }}
                  onClick={() => onClose()}
                >
                  <ArrowBackOutlinedIcon style={{ fill: "#010101" }} />
                </button>
              )}
            </div>
            <Typography
              className="pageTitle"
              align="left"
              variant="h3"
              component="h3"
              noWrap
            >
              Profile
            </Typography>
            <div></div>
          </div>

          <div className={isMobile && classes.profileBodyMob}>
            <FormWrapper
              formType="profile"
              config={signupConfig}
              fields={formFields}
              retailerName={dpnInstance}
            />
          </div>
        </div>
      </Box>
    </Paper>
  );
}

export default Profile;
