import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_USER, CLEAR_USER } from '../redux/reducers/user';
import { trackClick } from '../utils/gaTrackingFunctions';
import './registration.scss';

function RegistrationBlock(props) {
  const siteConfig = props.page
  const dispatch = useDispatch();
  let username = useSelector((state) => state.user.username);
  const storage = localStorage.getItem('pbyt');
  let links = siteConfig.colorLinks;

  if (!username && storage) {
    const storedUn = JSON.parse(storage).username;
    if (storedUn) {
      username = storedUn;
      dispatch(SET_USER({ state: 2, username: username }));
    }
  }

  function clearUser() {
    dispatch(CLEAR_USER());
    window.location.reload();
  }

  function RenderClearUser() {
    if (username) {
      return (
        <div>
          Not you?
          <button onClick={clearUser} style={{ color: links, margin: '10px' }}>
            Forget User
          </button>
        </div>
      );
    }
    return '';
  }

  function trackRegistrationEvent (){
    window.gtag('event', 'registration_link_click', { version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A' });
  }

  return (
      <section className="register-bar">
        {!username && (
          <span>
            Not a registered {props.retailer.internal_name} user yet?{' '}
            <a
              href={props.retailer.signup_link}
              onClick={() => trackClick('retailer_registration', 'main_page')}
              target="_blank"
              style={{ color: links }}
              rel="noopener noreferrer"
            >
              Click here
            </a>
          </span>
        )}
        <RenderClearUser />
      </section>
  );
}

export default RegistrationBlock;
