import axios from 'axios';

export async function getToken(){
    const myHeaders = new Headers();
    const raw = JSON.stringify({
      "action": "get_token"
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    const data =   await fetch(process.env.REACT_APP_REBATE_SUBMISSION_LAMBDA, requestOptions)
    const result  = await data.json()
    return result
}

export function submissionSearch(token, submissionNumber, emailAddress){
    
    let data = {
        "submissionNumber": submissionNumber,
        "emailAddress": emailAddress,
        "phoneNumber": "",
        "firstName": "",
        "lastName": "",
        "zipCode": "",
        "submissionDate": ""
      };
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_REBATE_TRACKING_URL,
        headers: { Authorization: `Bearer ${token}`,'Content-Type': 'application/json' },
        data : data
      };
      const response = axios.request(config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log(error);
      });
      return response
}