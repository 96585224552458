import { useSelector } from "react-redux";
import HeaderNavigation from "../blocks/headerNavigation";
import FooterV2 from "../footerV2/footer";
import getComponent from "../shared/util/index";
import { shouldRenderComponent } from "../shared/util/ComponentAuth";

function HomePage({ page }) {
  const { isLogin } = useSelector((state) => state.user);

  const retailerConfig = page[0];
  const homePageConfig = page.find(page =>
    page.slug === 'home' &&
    (isLogin
      ? ['both', 'authenticated'].includes(page.auth_visibility)
      : ['both', 'unauthenticated'].includes(page.auth_visibility))
  );

  const header = retailerConfig.components.find(
    (component) => component.block_type === "HeaderNavigationBlock"
  );
  const footer = retailerConfig.components.find(
    (component) => component.block_type === "FooterBlock"
  );

  if (!shouldRenderComponent(homePageConfig.auth_visibility)) {
    return null;
  }

  return (
    <>
      {header && <HeaderNavigation page={retailerConfig} />}

      {homePageConfig &&
        homePageConfig.components.map((blockConfig) => (
          <>
            {getComponent(
              blockConfig.block_type,
              homePageConfig,
              blockConfig,
              retailerConfig
            )}
          </>
        ))}

      {footer && <FooterV2 page={retailerConfig} />}
    </>
  );
}

export default HomePage;
