import React from "react";
import SubmissionForm from "./submissionForm";
import Submission from "./cashbackSubmissionSearch/Submission";
import { shouldRenderComponent } from '../shared/util/ComponentAuth';
import "./Rebates.scss";

function Rebates(page) {
  let pageData = page.page;

  if (!shouldRenderComponent(pageData.auth_visibility)) {
    return null;
  }

  return (
    <>
      <SubmissionForm page={pageData} />
      <Submission page={pageData}></Submission>
    </>
  );
}

export default Rebates;
