import React, { useState } from 'react'
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SubmissionInformation from './SubmissionInformation';
import { getToken, submissionSearch } from '../../service/submission_service';
import { validateEmailAddress } from "../../shared/util/UserValidations";
function Submission({page}) {

    // getting color from reabte model
    const component = page.components.filter((item)=>item.block_type==='Rebates')
    const {primaryColor,searchBoxTextColor } = component[0];

    function handleInput(value) {
        const result = validateEmailAddress(value, true)
        if (result === '') {
            setSubmissionEmail(value)
            setSubmissionNumber('')
        } else {
            setSubmissionNumber(value)
            setSubmissionEmail('')
        }
    }
    const [submissionNumber, setSubmissionNumber] = useState('');
    const [submissionEmail, setSubmissionEmail] = useState('');
    const [submissionResult, setSubmissionResult] = useState([])
    const [result, setResult] = useState(false)
    async function handleSearchResult() {
        const response = await getToken()
        const token = response.token
        const result = await submissionSearch(token, submissionNumber, submissionEmail)
        setSubmissionResult(result.data)
        setResult(true)
    }

    const useStyles = makeStyles((theme) => ({
        containerBox: {
            textAlign: 'center',
            padding: '48px 24px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F5F7F9',
            [theme.breakpoints.between("xs", "sm")]: {
                margin: '20px 0px',
                padding: '24px'
            },
        },
        title: {
            fontSize: '49px',
            color: `${primaryColor || '#002F57' }`,
            fontFamily:'Oswald',
            textTransform: 'uppercase',
            fontWeight: 500,
            lineHeight: '72.62px',
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: '31px'
            },
        },
        subTitle: {
            color: `${primaryColor || '#002F57' }`,
            fontSize: '20px',
            fontFamily:'Roboto',
            fontWeight: 400,
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: '16px'
            },
        },
        searchContainer: {
            marginTop: '32px',
            width: '100%',
        },
        searchBox: {
            display: 'flex',
            padding: '24px 16px',
            borderRadius: "12px",
            border: "1px solid #E0E6EB",
            backgroundColor: "#FFF",
            alignSelf: "center",
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: '100%',
            fontSize:'20px',
            color:`${searchBoxTextColor || '#E0E6EB'}`,
            fontFamily:'Roboto',

        },
        buttonContainer:{
            display:'flex',
            justifyContent:'center',

        },
        button: {
            display: 'flex',
            padding: '24px 48px',
            fontSize: '20px',
            fontWeight:700,
            borderRadius: "12px",
            border: "1px solid #0071CE",
            backgroundColor: "#FFF",
            maxWidth: "187px",
            alignSelf: "center",
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform:'uppercase',
            maxHeight:'71px',
            color:'#0071CE',
            fontFamily:'Roboto',
            [theme.breakpoints.down("xs")]: {
                minWidth:'100%',
                padding: '24px 0px',
                fontSize: '20px',
                fontWeight:700
            },
        },

    }));
    const classes = useStyles();
    return (
        <Grid className={classes.containerBox}>
            <div className={classes.title}>Check existing submission</div>
            <div className={classes.subTitle}>
                Search by submission number, email, phone, or full name and zip/postal together
            </div>
            <div className={classes.searchContainer}>
                <Grid container justifyContent='center' spacing={1}>
                    <Grid item xs={12} md={10} style={{maxWidth:'518px'}}>
                        <input className={classes.searchBox} placeholder="John@example.com" onChange={(e) => handleInput(e.target.value)}></input>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.buttonContainer}>
                        <Button className={classes.button} onClick={handleSearchResult}>Search</Button>
                    </Grid>
                </Grid>
            </div>
            <div>
                <SubmissionInformation data={submissionResult} result={result} primaryColor={primaryColor}></SubmissionInformation>
            </div>
        </Grid>

    )
}

export default Submission