import axios from 'axios';
import { getBaseUrl } from '../config/retailerConfig';
export const getProgramDetials = async (token, retailer) => {
    const headers = {
      'Content-Type': 'application/json',
      'x-retailer': retailer,
      'x-auth-token': token,
    };
    try {
      const response = await axios.get(`${getBaseUrl(retailer)}/loyalty?types=points_program`, { headers });
      response.data.token = token;
      return response.data;
    } catch (error) {
      return { error };
    }
  };
  export const getAvailableProgramDetials = async (token, retailer, groupType) => {
    const headers = {
      'Content-Type': 'application/json',
      'x-retailer': retailer,
      'x-auth-token': token,
    };
    try {
      const response = await axios.get(`${getBaseUrl(retailer)}/loyalty?groups=${groupType}&size=3`, { headers });
      response.data.token = token;
      return response.data;
    } catch (error) {
      return { error };
    }
  };
  export const enrollProgram = ( token, retailer, id) =>
  axios.post(
    `${getBaseUrl(retailer)}/loyalty/clip`,
    {
      id:id
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': retailer,
        'x-auth-token': token,
      },
    }
  );