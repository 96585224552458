import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { shouldRenderComponent } from '../shared/util/ComponentAuth';
import "../offerCarousel/offerCarouselStyles.scss";
import "./logoGarden.scss";

function GardenImages({ component }) {
  const pageConfig = component;
  const retailerConfig = useSelector((state) => state.config.retailerConfig);
  const [width, setWidth] = useState(window.innerWidth);

  const gardenStyle = {
    backgroundColor: pageConfig.color_background,
  };

  const imgStyle = {
    height: pageConfig.image_height,
  };

  const isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  if (!shouldRenderComponent(pageConfig.auth_visibility)) {
    return null;
  }

  function getImage(item) {
    if (isMobile && item.mobile_image) {
      return item.mobile_image;
    }
    return item.image;
  }

  function renderLinkAddress(item) {
    if (process.env.REACT_APP_PREVIEW_MODE === "true") {
      if (item.link) {
        return `${window.location.origin}/${retailerConfig.brandsite_name}/${item.link}`;
      } else {
        return `${window.location.origin}/${retailerConfig.brandsite_name}`;
      }
    } else {
      if (item.link) {
        return `${window.location.origin}/${item.link}`;
      } else {
        return `${window.location.origin}`;
      }
    }
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="garden-block-ctn">
      {!pageConfig.carousle.show_carousle && (
        <div className="garden" style={gardenStyle}>
          {pageConfig.gardenimage_set.map((item, i) => (
            <Link
              to={renderLinkAddress(item)}
              rel="noopener noreferrer"
              key={i}
            >
              <img
                className={`gardenImg`}
                src={getImage(item)}
                alt={item.alt_text || ""}
                style={imgStyle}
              />
            </Link>
          ))}
        </div>
      )}

      {pageConfig.carousle.show_carousle &&
        pageConfig.gardenimage_set.length > 1 && (
          <div className="carousel-ctn">
            <Carousel
              responsive={responsive}
              autoPlay={pageConfig.carousle.auto_scroll}
              autoPlaySpeed={1000}
              infinite={pageConfig.carousle.auto_scroll}
              arrows={pageConfig.carousle.show_arrows}
              centerMode={true}
              rtl={pageConfig.carousle.scrool_right_side}
            >
              {pageConfig.gardenimage_set.map((item, i) => (
                <img
                  className={`gardenImg`}
                  src={getImage(item)}
                  alt={item.alt_text || ""}
                  style={imgStyle}
                />
              ))}
            </Carousel>
          </div>
        )}
    </div>
  );
}

export default GardenImages;
