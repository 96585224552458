import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ProgramPod from './ProgramPod'
import { getAvailableProgramDetials, enrollProgram } from '../service/myloyalty_services';

function RecommendedProgrms(props) {
  const retailerConfig = useSelector((state) => state.config.retailerConfig);
  const [availablePrograms, setAvailablePrograms] = useState([])

  const useStyles = makeStyles((theme) => ({
    itemContainer:{
      padding:'80px 24px',
      justifyContent:'center',
      [theme.breakpoints.down("xs")]: {
          padding:'48px 16px',
          justifyContent:'flex-start'
        },
     },
     gridItem:{
       margin:'8px 8px',
       alignItems: 'center',
     }
  }));
  const classes=useStyles();
  
  useEffect(()=>{
    const token = localStorage.getItem('token');
    const retailer = retailerConfig.dpn_instance.internal_name
    getAvailableProgram(token, retailer);
  },[])

  function getAvailableProgram(token, retailer){
    const getPrograms = getAvailableProgramDetials(token, retailer, 'available');
    getPrograms
    .then(
        (res)=>
            {
                setAvailablePrograms(res.items)
              }
              
      )
      .catch((err)=>console.log(err))
  }
  function enrollCurrentProgram(id){
    const token = localStorage.getItem('token');
    const retailer = retailerConfig.dpn_instance.internal_name
    const response = enrollProgram(
        token, retailer, id
      );
      response
        .then((res) => {
          getAvailableProgram(token, retailer)
        }).catch((err)=>{
          console.log('error occurred on api call', err)
        })
  }

  return (
    <div>
        <Grid
            container
            direction="row"
            alignItems="stretch"
            className={classes.itemContainer}
          >
            <div>
            </div>
              {availablePrograms && availablePrograms.length>0 && availablePrograms.map((item, i) => (
              <Grid item key={i} className={classes.gridItem}>
                {' '}
                <ProgramPod enrollCurrentProgram={enrollCurrentProgram} programs={item} props={retailerConfig} value="available"></ProgramPod>
              </Grid>
            ))}
          </Grid>
     </div>
  )
}

export default RecommendedProgrms